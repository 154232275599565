<template>
    <div class="color-txt">
        <s-form @submit="addItem">
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Mating Program</h2>
        <div class="row">
            <div class="col-lg-9">
                <div class="row mt-4">
                    <div class="col-lg-3">
<!--                        <validated-vue-select label="Shed No." class="c-input-select text-black"></validated-vue-select>-->
                        <validated-ajax-vue-select class="c-input-select input-border-primary field-required" placeholder="Shed"
                                               v-model="shed_number" @input="loadCowOptions" label="Shed"
                                               :url="shedOptions" :rules="{required:true}"></validated-ajax-vue-select>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-lg-3">
                    <validated-vue-select :options="cowOptions" class="c-input-select field-required"
                                          label="Reg No" v-model="model.cow" :rules="{required:true}"></validated-vue-select>
                </div>

<!--                    <div class="col-lg-3">-->
<!--                        <validated-vue-select label="Reg No." class="c-input-select text-black"></validated-vue-select>-->
<!--                    </div>-->
                </div>
                <div class="row mt-2">
                    <div class="col-lg-3">
                        <validated-ajax-vue-select :url="bullOptions" @input="loadDetails"  label="Bull Allotted" v-model="model.bull_allotted" class="c-input-select text-black field-required" :rules="{required:true}"></validated-ajax-vue-select>
                    </div>
                    <div class="col-lg-3">
                        <validated-input label="Bull Breed" v-model="model.breed" class=" text-black" disabled></validated-input>
                    </div>
                </div>
                 <div class="row mt-2">
                    <div class="col-lg-3">
                        <validated-date-picker format="DD-MM-YYYY" label="Valid From" v-model="model.valid_from" class="c-input-datepicker text-black field-required" :rules="rules.ValidationDateFrom"></validated-date-picker>
                    </div>
                    <div class="col-lg-3">
                        <validated-date-picker format="DD-MM-YYYY" label="Valid To" v-model="model.valid_to" class="c-input-datepicker text-black field-required" :rules="rules.common"></validated-date-picker>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-3">
                        <validated-input label="Remarks" v-model="model.remarks" class=""></validated-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
    <custom-two-column-table heading1="Cow List" heading2="Bull Allotted">
        <template #column1>
            <ul class="list-unstyled list-items ml-2">
                <li v-for="(item, i) in model.details" :key="i">{{ item.value }}</li>
            </ul>
        </template>
        <template #column2>
            <ul class="list-unstyled list-items ml-2">
                <li v-for="(item, i) in model.details" :key="i">{{ item.label }}</li>
            </ul>
        </template>
    </custom-two-column-table>
</div>
        </div>
        <div class="row mt-lg-5">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                   <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                   </div>
                </div>
            </div>
        </div>
        </s-form>
    </div>
</template>
<script>
import CustomTwoColumnTable from '../../../components/ui/CustomTwoColumnTable';
import masterURLs from '../../../data/masterURLs';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'MatingProgram',
    components: { CustomTwoColumnTable },
    data () {
        return {
            shed_number: '',
            loading: false,
            URL: urls.mating_program.addEdit,
            model: {
                cow: '',
                bull_allotted: '',
                breed: '',
                valid_from: '',
                valid_to: '',
                remarks: '',
                details: []
            },
            rules: {
                ValidationDateFrom: {
                    required: true,
                    customValidator: (value) => {
                        return this.FromValidation(value);
                    }
                },
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                }
            },

            shedOptions: masterURLs.master.shed.vueSelect + '?animal=cow',
            bullOptions: urls.bulls.vueSelect,
            cowOptions: []
        };
    },
    methods: {
        Validation (date) {
            const fromDate = this.model.valid_from;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const fromDateParts = fromDate.split('-');
            if (fromDateParts.length !== 3) {
                return 'Please enter a valid Date.';
            }
            const fromDateDay = parseInt(fromDateParts[0], 10);
            const fromDateMonth = parseInt(fromDateParts[1], 10);
            const fromDateYear = parseInt(fromDateParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(fromDateDay) || isNaN(fromDateMonth) || isNaN(fromDateYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(fromDateYear, fromDateMonth - 1, fromDateDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the From Date';
            }

            return true;
        },
        FromValidation (date) {
            /*  const dob = this.model.dob; */
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            /* const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);
*/
            /* if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            } */

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            /* const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay); */

            /* if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            } */
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj < currentDate) {
                return 'Choose Date from today onwords.';
            }

            return true;
        },

        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.model.cow = '';
            const response = await axios.get(urls.mating_program.allOptions + '?shed_number=' + this.shed_number);
            this.cowOptions = response.data.cow.data;
            this.model.details = response.data.details;
        },
        async loadDetails () {
            const response = await axios.get(urls.bulls.details + '?id=' + this.model.bull_allotted);
            const data = response.data.data;
            this.model.breed = data.breed.breed_name;
            // this.model.age = data.age;
            // this.details.renumber = data.renumber;
            // this.details.breed = data.breed.breed_name;
            // this.details.dob = data.dob;
            // this.details.shed_number = data.shed_number.name;
            // this.details.animalno_or_name = data.animalno_or_name;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/ah-section/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>;
